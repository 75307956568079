import React from 'react'
import Skeleton from 'react-loading-skeleton'

type Props = {
  isLoading: boolean
  children: React.ReactElement
  height?: number
  width?: number
  className?: string
}

export default function Loading({ isLoading, children, height = 24, width = 24, className = '' }: Props) {
  if (isLoading) {
    return <Skeleton height={height} width={width} baseColor="#202020" highlightColor="#444" className={className} />
  }
  return <>{children}</>
}
