import { gql, useQuery } from '@apollo/client'
import { apolloClient } from 'graphql/thegraph/apollo'
import { useMemo } from 'react'

const GET_DOCS =gql`
query transactions($address: Bytes!) {
  mints(
    first: 100
    orderBy: timestamp
    orderDirection: desc
    where: {pool: $address}
    subgraphError: allow
  ) {
    timestamp
    transaction {
      id
    }
    pool {
      token0 {
        id
        symbol
      }
      token1 {
        id
        symbol
      }
    }
    owner
    sender
    origin
    amount0
    amount1
    amountUSD
  }
  swaps(
    first: 100
    orderBy: timestamp
    orderDirection: desc
    where: {pool: $address}
    subgraphError: allow
  ) {
    timestamp
    transaction {
      id
    }
    pool {
      token0 {
        id
        symbol
      }
      token1 {
        id
        symbol
      }
    }
    sender
    origin
    origin
    amount0
    amount1
    amountUSD
  }
}
`;
// Define token type
interface Token {
  id: string;
  symbol: string;
  __typename: string;
}

// Define pool type
interface Pool {
  token0: Token;
  token1: Token;
  __typename: string;
}

// Define transaction type
interface Transaction {
  id: string;
  __typename: string;
}

// Define mint type
interface Mint {
  timestamp: string;
  transaction: Transaction;
  pool: Pool;
  owner: string;
  sender: string;
  origin: string;
  amount0: string;
  amount1: string;
  amountUSD: string;
  __typename: string;
}

// Define swap type
interface Swap {
  timestamp: string;
  transaction: Transaction;
  pool: Pool;
  sender: string;
  origin: string;
  amount0: string;
  amount1: string;
  amountUSD: string;
  __typename: string;
}

// Define burn type
interface Burn {
  timestamp: string;
  transaction: Transaction;
  pool: Pool;
  owner: string;
  amount0: string;
  amount1: string;
  amountUSD: string;
  __typename: string;
}

// Define the main type for query result
interface TransactionsData {
  mints: Mint[];
  swaps: Swap[];
}


export const TYPE = {
  "Mint" : "Add LP"
}


export function useGetDetailTransaction(id?: string) {


  const data = useQuery<TransactionsData>(GET_DOCS, {
    client: apolloClient,
    variables: {
      address: id,
    },
  })    

  console.debug('data' , data)

  return data
}
