import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useGetDetailPool } from './data'
import { getSymbol } from './Transaction'
import { shortenAddress } from 'utilities/src/addresses'
import iconCopy from 'assets/svg/copy.svg'
import { CopyHelper } from 'theme/components'
import Loading from 'components/Loading/Loading'

export default function Breadcrumbs() {
  const { id } = useParams<{ id?: string }>()
  const { data: dataPoolDetail, loading } = useGetDetailPool(id)
  return (
    <div>
      <div className="flex items-center gap-1 mb-4">
        <Link to={'/pool'} className="text-sm text-[#4C4C4C]">
          Pool {'>'}
        </Link>
        <Loading isLoading={loading} width={150} className="rounded-lg">
          <span className="truncate text-sm text-white ">{`${getSymbol(
            dataPoolDetail?.pool.token0.symbol || ''
          )}/${getSymbol(dataPoolDetail?.pool.token1.symbol || '')}`}</span>
        </Loading>

        <CopyHelper iconSize={14} iconPosition="right" toCopy={id ?? ''}>
          <span className="text-sm text-[#9B9B9B]">{shortenAddress(id)}</span>
        </CopyHelper>
      </div>
    </div>
  )
}
