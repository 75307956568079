import { Stats } from './Stas'
import { Link } from 'react-router-dom'
import { Transaction } from './Transaction'
import Header from './header'
import Breadcrumbs from './Breadcrumbs'

export function AllPoolDetail() {
  return (
    <div className="w-full max-w-[1232px] px-[16px] mx-auto mt-[64px] ">
      <Breadcrumbs />
      <div className="md:hidden block ">
        <Header />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-[1fr_389px] gap-[48px]">
        <Transaction />
        <div className="md:sticky md:top-[150px]  order-0 md:order-1 md:h-[600px] bg-black">
          <Stats />
        </div>
      </div>
    </div>
  )
}
