import { gql, useQuery } from '@apollo/client'
import { apolloClient } from 'graphql/thegraph/apollo'
import { useMemo } from 'react'

const GET_DOCS = gql`
  query Pool($id: String!, $timestamp: Int!) {
    pool(id: $id) {
      token0 {
        id
        symbol
        decimals
      }
      token1 {
        id
        symbol
        decimals
      }
      volumeUSD
      reserve0: totalValueLockedToken0
      reserve1: totalValueLockedToken1
      totalValueLockedUSD
      feesUSD
      feeTier
      poolHourData(where: { periodStartUnix_gt: $timestamp }) {
        periodStartUnix
        volumeUSD
      }
    }
  }
`

interface DetailPoolResponse {
  pool: {
    token0: {
      id: string
      symbol: string
      decimals: string
    }
    token1: {
      id: string
      symbol: string
      decimals: string
    }
    reserve0: string
    reserve1: string
    totalValueLockedUSD: string
    volumeUSD: string
    feesUSD: string
    feeTier: string
    poolHourData: {
      feesUSD: string
      volumeUSD: string
    }[]
  }
}

export function useGetDetailPool(id?: string) {
  const oneday = useMemo(() => {
    return Math.floor(Date.now() / 1000) - 1 * 24 * 60 * 60
  }, [])

  const data = useQuery<DetailPoolResponse>(GET_DOCS, {
    client: apolloClient,
    variables: {
      id: id,
      timestamp: oneday,
    },
  })

  return data
}
