import { LogoImage } from 'pages/Pool/all-pool'
import React, { memo } from 'react'
import { useParams } from 'react-router-dom'
import { useGetDetailPool } from './data'
import { formatDisplay } from 'utils/formatNumbers'
import { getSymbol } from './Transaction'

function Header() {
  const { id } = useParams<{ id?: string }>()
  const { data: dataPoolDetail } = useGetDetailPool(id)
  return (
    <div className="flex items-center gap-x-2 mb-4 ml-3">
      <LogoImage id0={dataPoolDetail?.pool.token0.id ?? ''} id1={dataPoolDetail?.pool.token1.id ?? ''} />
      <span className="truncate text-2xl">{`${getSymbol(dataPoolDetail?.pool.token0.symbol || '')}/${getSymbol(
        dataPoolDetail?.pool.token1.symbol || ''
      )}`}</span>
      {dataPoolDetail?.pool.feesUSD && (
        <span className="text-[#4C4C4C] border border-[#242424] rounded-full text-sm p-1">
          {formatDisplay(Number(dataPoolDetail?.pool.feeTier) / 10000 || 0, { decimalToShow: 2 })}%
        </span>
      )}
    </div>
  )
}

export default memo(Header)
